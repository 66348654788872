import {
    Paper, Table, TableBody, TableContainer, TableHead, TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import React from "react";
import { LineData } from "../../types/line-data";

export interface Percentiles {
    startRatingPercentile: { [key: string]: number | null }
    endRatingPercentile: { [key: string]: number | null }
}

interface Props {
    dataSet: LineData[];
    startRatings: { [key: string]: number };
    percentiles: Percentiles;
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#2F2F2F",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export function findEndRating(lineData: LineData | undefined): number {
    if (!lineData) {
        return NaN;
    }

    const { data } = lineData;
    const ratings = data.map((coordinate) => coordinate.y);
    const rating = ratings.reverse().find((line) => !!line) || NaN;
    return Math.round(rating * 100) / 100;
}

export function PlayerRatingTable({ dataSet, startRatings, percentiles }: Props): JSX.Element {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left" style={{ fontSize: "1.1em" }}>Rating Algorithm</StyledTableCell>
                        <StyledTableCell align="left" style={{ fontSize: "1.1em" }}>Start Rating</StyledTableCell>
                        <StyledTableCell align="left" style={{ fontSize: "1.1em" }}>End Rating</StyledTableCell>
                        <StyledTableCell align="left" style={{ fontSize: "1.1em" }}>Percentile</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataSet.map((row) => (
                        <StyledTableRow key={`${row.label}`}>
                            <StyledTableCell align="left" style={{ fontSize: "1em" }}>{row.label}</StyledTableCell>
                            <StyledTableCell align="left" style={{ fontSize: "1em" }}>
                                {Math.round(startRatings[row.label] * 100) / 100 || "n/a"}
                            </StyledTableCell>
                            <StyledTableCell align="left" style={{ fontSize: "1em" }}>{findEndRating(row) || "n/a"}</StyledTableCell>
                            <StyledTableCell align="left" style={{ fontSize: "1em" }}>
                                {
                                    percentiles.endRatingPercentile[row.label] || percentiles.endRatingPercentile[row.label] === 0
                                        ? `${percentiles.endRatingPercentile[row.label]}%`
                                        : "-"
                                }
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
