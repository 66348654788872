import pattern from "patternomaly";
import {
    CHART_COLORS, PTI_V2, RATERS_COLORS,
} from "../constants";
import { LineData } from "../types/line-data";
import {
    Match, MatchSim, MatchTS, PlayerMatchHistorySim, PlayerMatchHistoryTS,
} from "../types/match-history";
import { getDateByUtc } from "./date";
import { getDataLine } from "./line-chart";

export const AWAY = "away";
export const HOME = "home";

export function isNotNullOrUndefined(value: number | string) {
    return value !== null && value !== undefined;
}

export function sortMatchesDescending<T>(matches: MatchSim[] | MatchTS[]): T {
    return matches.sort((match1, match2) => {
        const date1 = new Date(match1.date);
        const date2 = new Date(match2.date);
        if (date1 <= date2) {
            return -1;
        }
        return 1;
    }) as T;
}

export function getColorArraySkippedMatces(
    matches: Match[],
    lineColor: string,
    patternColor: string,
    skipColor = CHART_COLORS.black,
): Array<string | CanvasPattern> {
    return matches.map((match) => {
        if (match.player_scale === -1 || !match.date) {
            return pattern.draw("cross", skipColor, patternColor);
        }

        return lineColor;
    });
}

export function transformTsMatchesToLineData(tenniscoresResp: PlayerMatchHistoryTS, label = ""): LineData {
    const tennisScoresCoordinates = tenniscoresResp.match_history
        .filter((match) => isNotNullOrUndefined(match.player_pti_end))
        .map((match) => ({
            x: getDateByUtc(match.date),
            y: match.player_pti_end,
        }));
    const tsColorArr = getColorArraySkippedMatces(
        tenniscoresResp.match_history,
        CHART_COLORS.yellow,
        CHART_COLORS.white,
        CHART_COLORS.black,
    );

    return getDataLine(label || PTI_V2, tsColorArr, CHART_COLORS.yellow, tennisScoresCoordinates, "y1");
}

export function transformSimMatchesToLineData(sim: PlayerMatchHistorySim, label = ""): LineData {
    const sortedMatches = sortMatchesDescending<MatchSim[]>(sim.match_history);
    const data = sortedMatches
        .filter((match) => isNotNullOrUndefined(match.rating_after))
        .map((match) => ({
            x: getDateByUtc(match.date),
            y: match.rating_after,
        }));
    const simColorArr = getColorArraySkippedMatces(
        sim.match_history,
        RATERS_COLORS[sim.rater],
        CHART_COLORS.black,
        CHART_COLORS.white,
    );
    const yAxisID = "y1";
    return getDataLine(label || sim.rater, simColorArr, RATERS_COLORS[sim.rater], data, yAxisID);
}

export function transformManySimsToLineData(sims: PlayerMatchHistorySim[], label = ""): LineData[] {
    return sims.map((sim) => transformSimMatchesToLineData(sim, label));
}

/**
WinnerID WinDesc
 * 0 Score Not Entered
 * 1 Home Win
 * 2 Away Win
 * 3 Home Win by Forfeit
 * 4 Away Win by Forfeit
 * 5 Home Win by Default
 * 6 Away Win by Default
 * 7 Home Win by Retired
 * 8 Away Win by Retired
 * 9 Tie
 * 10 Double Default
 * 11 Double Forfeit
 * 12 Unknown
 * 13 Home by Early Default
 * 14 Away by Early Default
 * 15 Home by Late Default
 * 16 Away by Late Default
 * 17 Unknown
 * 18 Unknown
 * 19 Unknown
 * 20 Unknown
 * 21 Home by Protest
 * 22 Away by Protest
 */
export function getWinner(winner: number): string {
    if (winner === 9) {
        return "tie";
    }

    if ([1, 3, 5, 7, 13, 15, 21].includes(winner)) {
        return HOME;
    }

    if ([2, 4, 6, 8, 14, 16, 22].includes(winner)) {
        return AWAY;
    }

    return "unknown";
}
