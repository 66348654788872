import Box from "@mui/material/Box";
import Card from "@mui/material/Card/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import React from "react";

interface Props {
    endRating: number;
    matchCount: number;
    playerName: string;
}

export default function PlayerDashBoard({
    endRating, matchCount, playerName,
}: Props): JSX.Element {
    const [loading, setLoading] = React.useState(true);

    // styling
    const theme = useTheme();
    const desktopMatch = useMediaQuery(theme.breakpoints.up("md"));
    const statBoxDimensions = {
        minHeight: 75,
        width: 150,
    };
    React.useEffect(() => {
        if (matchCount) {
            setLoading(false);
        }
    }, [matchCount]);

    if (loading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", margin: 5 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Grid
            container
            spacing={1}
        >
            {
                !desktopMatch && (
                    <Grid xs={12}>
                        <Typography variant="h5" align="center" data-testid="player_playername">{playerName}</Typography>
                    </Grid>
                )
            }
            {
                desktopMatch && (
                    <Grid xs={2.5} display="flex" justifyContent="center" alignItems="center">
                        <Typography
                            variant="h5"
                            data-testid="player_playername"
                        >
                            {playerName}
                        </Typography>
                    </Grid>
                )
            }
            <Grid xs={desktopMatch ? 9.5 : 12}>
                <Stack direction="row" spacing={1} display="flex" justifyContent={desktopMatch ? "left" : "center"}>
                    <Card sx={{ justifyContent: "center", display: "flex", ...statBoxDimensions }}>
                        <Stack direction="column" alignContent="center" justifyContent="center">
                            <Typography variant="h5" align="center" data-testid="">{endRating.toFixed(1)}</Typography>
                            <Typography variant="body1">current rating</Typography>
                        </Stack>
                    </Card>
                    <Card sx={{ justifyContent: "center", display: "flex", ...statBoxDimensions }}>
                        <Stack direction="column" alignContent="center" justifyContent="center">
                            <Typography variant="h5" align="center" data-testid="player_matchesplayed">{matchCount}</Typography>
                            <Typography variant="body1">matches rated</Typography>
                        </Stack>
                    </Card>
                </Stack>
            </Grid>
        </Grid>
    );
}
