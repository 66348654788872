import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import React from "react";
import { RATERS_AVAILABLE } from "../../constants";

interface Props {
    // newStartRatings
    defaultChecked: Record<string, boolean>;
    open: boolean;
    handleChange: (selectedRaters: Record<string, boolean>) => void;
}

export default function RaterCheckboxGroupDialog({ defaultChecked, open, handleChange }: Props): JSX.Element {
    const raters = [...RATERS_AVAILABLE];
    const [value, setValue] = React.useState(defaultChecked);
    const [maybeValue, setMaybeValue] = React.useState(defaultChecked);

    const error = !Object.keys(maybeValue).find((r) => maybeValue[r]);

    const handleSelectionChange = (event: React.ChangeEvent<HTMLInputElement>, rater: string) => {
        setMaybeValue({
            ...maybeValue,
            [rater]: event.target.checked,
        });
    };

    const handleClose = () => {
        setMaybeValue(value);
        handleChange(value);
    };

    const handleSave = () => {
        if (error) {
            return;
        }

        setValue(maybeValue);
        handleChange(maybeValue);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ minWidth: 230 }}>Rater Setting</DialogTitle>
            <DialogContent>
                <FormControl error={error}>
                    <FormGroup
                        data-testid="raters_checkbox"
                    >
                        {
                            raters.map((rater) => (
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={maybeValue[rater]}
                                            onChange={(event) => handleSelectionChange(event, rater)}
                                            data-testid={`checkbox_${rater}`}
                                        />
                                    )}
                                    key={rater}
                                    label={rater}
                                />
                            ))
                        }
                    </FormGroup>
                    {error && <FormHelperText>At least 1 rater must be selected</FormHelperText>}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} data-testid="RaterCheckBoxGroupDialog_cancel">Cancel</Button>
                <Button onClick={handleSave} data-testid="RaterCheckBoxGroupDialog_save">Save</Button>
            </DialogActions>
        </Dialog>
    );
}
