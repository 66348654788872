import { DirectoryEntry } from "@apta/data/dist/directory";
import { SimJobStatus } from "@apta/data/dist/simulation";
import InfoIcon from "@mui/icons-material/Info";
import TuneIcon from "@mui/icons-material/Tune";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import Grid from "@mui/system/Unstable_Grid";
import React from "react";
import { config } from "../../config";
import {
    LIVE_RATER,
    RATERS,
    RATERS_AVAILABLE,
    RATERS_OVERRIDE_PREFIXES,
} from "../../constants";
import { useAuthContext } from "../../hooks/AuthProvider";
import { LineData } from "../../types/line-data";
import {
    MatchSim, PlayerMatchHistorySim, PlayerMatchHistoryTS,
} from "../../types/match-history";
import { LocalStoragePlayers, NewStartRate } from "../../types/player";
import { getDateByUtc } from "../../utils/date";
import {
    formatSimDataLineLabel,
    formatTenniscoresDataLineLabel,
    getDataLine,
    primaryNewSimLabel,
    primaryNewSimSecondaryRawSim,
    secondaryNewSimLabel,
    secondaryNewSimSecondaryRawSim,
} from "../../utils/line-chart";
import {
    isNotNullOrUndefined,
    sortMatchesDescending,
    transformSimMatchesToLineData,
    transformTsMatchesToLineData,
} from "../../utils/match";
import PlayersAutoComplete from "../Common/PlayersAutoComplete";
import OverrideRatingDialog from "../Dialogs/OverrideRatingDialog";
import RaterCheckboxGroupDialog from "../Dialogs/RaterCheckboxGroupDialog";
import { Percentiles } from "../Players/PlayerRatingTable";
import { LineChart } from "./LineChart";
import ChartInfoDialog from "../Dialogs/ChartInfoDialog";

export interface OtherPlayersLocalStorage {
    [playerId: number]: {
        theirOtherPlayers: DirectoryEntry[]
    },
}

function randomColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r},${g},${b})`;
}

function sameNewStartRating(newStartRatingA: NewStartRate, newStartRatingB: NewStartRate) {
    const sameRating = newStartRatingA.rating === newStartRatingB.rating;
    const sameRater = newStartRatingA.rater === newStartRatingB.rater;
    const samePlayer = newStartRatingA.playerId === newStartRatingB.playerId;
    const sameRapid = newStartRatingA.rapid === newStartRatingB.rapid;
    return sameRating && sameRater && samePlayer && sameRapid;
}

function addPlayerLocalStorage(playerId: number, playerName: string, newStartRating: NewStartRate): void {
    const currentPlayers = JSON.parse(window.localStorage.getItem("players") || "[]") as LocalStoragePlayers[];
    const playerLocalStorage = currentPlayers.find((player) => player.playerId === playerId);
    if (playerLocalStorage) {
        const newStartRatingEntry = playerLocalStorage.newStartRatings.find((nsr) => sameNewStartRating(nsr, newStartRating));

        if (newStartRatingEntry
            && newStartRatingEntry.include
            && newStartRating.include) {
            newStartRatingEntry.include = [...new Set([...newStartRatingEntry.include, ...newStartRating.include])];
        } else {
            playerLocalStorage.newStartRatings = [...playerLocalStorage.newStartRatings, newStartRating];
        }
        window.localStorage.setItem("players", JSON.stringify([...currentPlayers]));
    } else {
        window.localStorage.setItem("players", JSON.stringify([...currentPlayers, { newStartRatings: [newStartRating], playerId, playerName }]));
    }
}

function removePlayerLocalStorage(playerId: number): void {
    const currentPlayers = JSON.parse(window.localStorage.getItem("players") || "[]") as LocalStoragePlayers[];
    const playerLocalStorage = currentPlayers.find((player) => player.playerId === playerId);
    if (playerLocalStorage) {
        currentPlayers.splice(currentPlayers.indexOf(playerLocalStorage), 1);
        window.localStorage.setItem("players", JSON.stringify([...currentPlayers]));
    }
}

function addOtherPlayersLocalStorage(playerId: number, newPlayers: DirectoryEntry[]) {
    const otherPlayers = JSON.parse(window.localStorage.getItem("otherPlayers") || "[]") as OtherPlayersLocalStorage;
    const otherPlayer = otherPlayers[playerId];
    if (otherPlayer) {
        otherPlayer.theirOtherPlayers = newPlayers;
        localStorage.setItem("otherPlayers", JSON.stringify({ ...otherPlayers }));
    } else {
        localStorage.setItem("otherPlayers", JSON.stringify({ ...otherPlayers, [playerId]: { theirOtherPlayers: newPlayers } }));
    }
}

function getDefaultCheckedRaters() {
    const settings = JSON.parse(window.localStorage.getItem("checkedRaters") || "{}") as Record<string, boolean>;

    if (settings[RATERS_AVAILABLE[0]]) {
        return settings;
    }

    const defaultCheckedRaters: Record<string, boolean> = {};
    [...RATERS_AVAILABLE].forEach((rater) => {
        defaultCheckedRaters[rater] = LIVE_RATER === rater;
    });

    return defaultCheckedRaters;
}

function getDefaultOtherPlayers(playerId: number) {
    const otherPlayers = JSON.parse(window.localStorage.getItem("otherPlayers") || "{}") as OtherPlayersLocalStorage;

    if (otherPlayers[playerId]) {
        return otherPlayers[playerId].theirOtherPlayers;
    }

    return [];
}

function isOtherPlayerLabel(label: string, playerId: number) {
    return label.startsWith("_") && label.includes(playerId.toString());
}

function simulationRunContainsPlayerId(sims: PlayerMatchHistorySim[], playerId: number) {
    const player = sims.find((sim) => sim.player_id === playerId);

    if (player) {
        return true;
    }

    return false;
}

function formatLineChartLabel(
    simResp: PlayerMatchHistorySim,
    playerId: number,
    newStartRating: NewStartRate,
    primaryPlayerInSimRun: boolean,
) {
    let lineChartLabel = "n/a";

    if (playerId === simResp.player_id) {
        lineChartLabel = primaryNewSimLabel(
            simResp.rater,
            simResp.new_start_rating,
            newStartRating.rapid,
        );
    } else if (
        primaryPlayerInSimRun
        && playerId !== simResp.player_id
        && !simResp.new_start_rating
    ) {
        lineChartLabel = primaryNewSimSecondaryRawSim(
            simResp.rater,
            simResp.player_id,
            simResp.player_name,
            newStartRating.rating,
            newStartRating.rapid,
        );
    } else if (
        !primaryPlayerInSimRun
        && playerId !== simResp.player_id
        && !simResp.new_start_rating
    ) {
        lineChartLabel = secondaryNewSimSecondaryRawSim(
            simResp.rater,
            newStartRating.rating,
            simResp.player_id,
            simResp.player_name,
            newStartRating.rapid,
            newStartRating.playerName,
        );
    } else if (
        playerId !== simResp.player_id
        && simResp.new_start_rating
    ) {
        lineChartLabel = secondaryNewSimLabel(
            simResp.rater,
            simResp.new_start_rating,
            simResp.player_id,
            simResp.player_name,
            newStartRating.rapid,
        );
    }

    return lineChartLabel;
}

function buildRaterKey(newStartRating: NewStartRate) {
    return `${newStartRating.playerId}_${newStartRating.rater}_${newStartRating.rating}`;
}

function pollingDelay(ms: number) {
    return new Promise((res) => { setTimeout(res, ms); });
}

async function pollSimJob(jobId: string, handleGetRequest: <T>(url: string) => Promise<T>): Promise<PlayerMatchHistorySim[] | null> {
    const pollingInterval = 15000; // 15 seconds in milliseconds
    const ttl = 600000; // 10 minutes in milliseconds
    const startTime = Date.now();

    let polling = true;
    let data = null;

    while (polling) {
        // eslint-disable-next-line no-await-in-loop
        await pollingDelay(pollingInterval);

        // eslint-disable-next-line no-await-in-loop
        data = await handleGetRequest<SimJobStatus>(`${config.apiStandard}/api/simulation/job?jobId=${jobId}`)
            .then((response) => {
                if (["Failed", "Completed"].includes(response.status)) {
                    return response.data as PlayerMatchHistorySim[];
                }

                return null;
            })
            .catch((error) => {
                const errorMessage = error instanceof Error ? error.message : "unkown";
                throw Error(`GET /api/simulation/job?jobId=${jobId} [${errorMessage}]`);
            });

        const timeout = Date.now() - startTime > ttl;

        if (data || timeout) {
            polling = false;
        }
    }

    return data;
}

interface Props {
    dataSet: LineData[];
    initialNewStartRatings: NewStartRate[];
    playerId: number;
    playerName: string;
    setDataSet: React.Dispatch<React.SetStateAction<LineData[]>>;
    setPercentiles: React.Dispatch<React.SetStateAction<Percentiles>>;
    setStartRatings: React.Dispatch<React.SetStateAction<{ [key: string]: number; }>>;
}

export default function LineChartInteractive({
    dataSet,
    playerId,
    playerName,
    initialNewStartRatings,
    setDataSet,
    setPercentiles,
    setStartRatings,
}: Props): JSX.Element {
    // auth
    const authContext = useAuthContext();
    const { handleGetRequest, handlePostRequest } = authContext;

    // styling
    const theme = useTheme();
    const desktopMatch = useMediaQuery(theme.breakpoints.up("md"));

    // start rating state
    const [renderedRatings, setRenderedRatings] = React.useState<NewStartRate[]>([]);
    const [duplicate, setDuplicate] = React.useState(false);
    const [resetSuccess, setResetSuccess] = React.useState(false);

    // adding a player state (from autocomplete component)
    const [openTuneDialog, setOpenTuneDialog] = React.useState(false);
    const [checkedRaters, setCheckedRaters] = React.useState(getDefaultCheckedRaters());
    const [otherPlayers, setOtherPlayers] = React.useState<DirectoryEntry[]>([]);
    const defaultOtherPlayers = getDefaultOtherPlayers(playerId);

    // including additional players from a simulation
    const [polling, setPolling] = React.useState(false);
    const [pollingAlert, setPollingAlert] = React.useState(false);

    // miscellaneous
    const [chartInfo, setChartInfo] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState<string[]>([]);

    /**
     * Load new start rating line(s) onto line chart
     */
    const processSimResponse = (
        playerMatchHistorySim: PlayerMatchHistorySim,
        label: string,
    ) => {
        const dataSetEntry = dataSet.find((ds) => ds.label === label);

        if (dataSetEntry) { // don't process duplicate
            return;
        }

        const yAxisID = "y1";
        const newRatingDataSorted = sortMatchesDescending<MatchSim[]>(playerMatchHistorySim.match_history);
        const data = newRatingDataSorted.map((match) => ({
            x: getDateByUtc(match.date),
            y: match.rating_after,
        }));
        const color = randomColor();
        const newRatingLineData = getDataLine(label, [color], color, data, yAxisID);

        setDataSet((current) => {
            current.push(newRatingLineData);
            return [...current];
        });
        setStartRatings((current) => ({
            ...current,
            [label]: playerMatchHistorySim.match_history[0]?.rating_before,
        }));
        setPercentiles((current) => ({
            startRatingPercentile: {
                ...current.startRatingPercentile,
            },
            endRatingPercentile: {
                ...current.endRatingPercentile,
                [label]: playerMatchHistorySim.end_rating_percentile,
            },
        }));
    };

    const handleNewStartRating = (newStartRating: NewStartRate) => {
        if (!newStartRating.rapid) {
            return;
        }

        setLoading(true);

        handlePostRequest<PlayerMatchHistorySim[]>(
            `${config.apiSimulation}/api/simulation`,
            JSON.stringify({
                rater: newStartRating.rater,
                start_ratings: [
                    {
                        start_rating: newStartRating.rating,
                        player_id: newStartRating.playerId,
                    },
                ],
                include: newStartRating.include,
            }),
            "application/json",
        ).then((simResponses) => {
            const primaryPlayerInSimRun = simulationRunContainsPlayerId(simResponses, playerId);

            simResponses.forEach((simResp) => {
                const lineChartLabel = formatLineChartLabel(simResp, playerId, newStartRating, primaryPlayerInSimRun);
                processSimResponse(simResp, lineChartLabel);
                setRenderedRatings((current) => {
                    const existingStartRating = current.find((nsr) => sameNewStartRating(nsr, newStartRating));

                    if (existingStartRating
                        && existingStartRating.include
                        && newStartRating.include) {
                        existingStartRating.include = [...new Set([...existingStartRating.include, ...newStartRating.include])];
                        return [...current];
                    }

                    return [...current, newStartRating];
                });
            });

            setLoading(false);
        }).catch((error: Error) => {
            setErrors((currentErrors) => {
                currentErrors.push(error.message);
                return [...currentErrors];
            });
        });
    };

    /**
     * Add other players onto line chart using search auto complete
     * handleAddOtherPlayers is intended to be called by line chart
     * autoComplete component
     */
    const addOtherPlayerToLineChart = (directoryEntry: DirectoryEntry) => {
        if (checkedRaters[LIVE_RATER]) {
            setLoading(true);
            handleGetRequest<PlayerMatchHistoryTS>(`${config.apiStandard}/api/matches_unified_byplayer?algo=pti2&playerId=${directoryEntry.player_id}`)
                .then((tenniscoresResp) => {
                    const label = `_${formatTenniscoresDataLineLabel(tenniscoresResp.player_name, tenniscoresResp.player_id)}`;
                    const tenniScoresLineData = transformTsMatchesToLineData(tenniscoresResp, label);
                    const filteredTSMatches = tenniscoresResp.match_history.filter((match) => isNotNullOrUndefined(match.player_pti_end));

                    setDataSet((current) => {
                        current.push(tenniScoresLineData);
                        return [...current];
                    });

                    setStartRatings((current) => ({
                        ...current,
                        [label]: filteredTSMatches[0]?.player_pti,
                    }));

                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }

        const raterReqs = RATERS
            .filter((rater) => checkedRaters[rater])
            .map((rater) => {
                const url = `${config.apiSimulation}/api/simulation/players/${directoryEntry.player_id}/rating_history?rater=${rater}`;
                return handleGetRequest<PlayerMatchHistorySim>(url);
            });

        Promise.all(raterReqs).then(([...raterResps]) => {
            try {
                setLoading(true);
                raterResps.forEach((simData) => {
                    const label = `_${formatSimDataLineLabel(simData.player_name, simData.player_id, simData.rater)}`;
                    const raterRespLineData = transformSimMatchesToLineData(simData, label);

                    setDataSet((current) => {
                        current.push(raterRespLineData);
                        return [...current];
                    });

                    setStartRatings((current) => ({
                        ...current,
                        [label]: simData.match_history[0]?.rating_before,
                    }));

                    setPercentiles((current) => ({
                        ...current,
                        endRatingPercentile: {
                            ...current.endRatingPercentile,
                            [label]: simData.end_rating_percentile,
                        },
                    }));
                });
            } catch (error) {
                setLoading(false);
            } finally {
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        });
    };

    const handleAddOtherPlayers = (newPlayers: DirectoryEntry[] | null) => {
        if (!newPlayers) {
            return;
        }
        // Filter out players that are removed from auto complete search component
        setDataSet((current) => {
            const playerIds = newPlayers.map((op) => op.player_id);
            return current.filter((ds) => {
                if ([...RATERS_AVAILABLE].includes(ds.label)) {
                    return true;
                }

                // make sure not to remove new start ratings for other players.
                // other players on LineChart will start with '_' prefix that come
                // from autocomplete feature.
                // new start rating feature has its own naming convention
                if (!ds.label.startsWith("_")) {
                    return true;
                }

                return !!playerIds.find((pId) => ds.label.includes(pId.toString()));
            });
        });

        // Find the player that is not in LineChart dataset that comes from auto complete
        // search component. One player is added at a time through auto complete feature, but
        // the same set of selected players is always an input
        const directoryEntry = newPlayers.find((newPlayer) => {
            const result = otherPlayers.find((data) => data.player_id === newPlayer.player_id);
            return !result;
        });

        setOtherPlayers(newPlayers);
        addOtherPlayersLocalStorage(playerId, newPlayers);

        if (!directoryEntry) {
            return;
        }

        addOtherPlayerToLineChart(directoryEntry);
    };

    /**
     * Include other players from a player's simulation.
     *
     * handleSimIncludedPlayers is used in start rating dialog to include
     * players from a specific player's simulation.
     * I'll see what happens to data of players B, C, etc, if I simulate player A start rating.
     */
    const handleSimIncludePlayers = (newStartRating: NewStartRate, rapid: boolean, saveCache = true) => {
        setPolling(true);

        async function handleSimIncludePlayersAsync() {
            const data = JSON.stringify({
                start_ratings: [
                    {
                        player_id: newStartRating.playerId,
                        start_rating: newStartRating.rating,
                    },
                ],
                rater: newStartRating.rater,
                include: newStartRating.include,
                rapid,
                tolerance: rapid ? 0.05 : 0,
            });

            await handlePostRequest<SimJobStatus>(`${config.apiStandard}/api/simulation/job`, data, "application/json")
                .then((simJobResponse) => {
                    pollSimJob(simJobResponse.job_id, handleGetRequest)
                        .then((playerMatchHistorySims) => {
                            if (playerMatchHistorySims) {
                                const primaryPlayerInSimRun = simulationRunContainsPlayerId(playerMatchHistorySims, playerId);

                                playerMatchHistorySims.forEach((pmhs) => {
                                    const lineChartLabel = formatLineChartLabel(pmhs, playerId, newStartRating, primaryPlayerInSimRun);
                                    processSimResponse(pmhs, lineChartLabel);

                                    setRenderedRatings((current) => {
                                        const existingStartRating = current.find((nsr) => sameNewStartRating(nsr, newStartRating));

                                        if (existingStartRating
                                            && existingStartRating.include
                                            && newStartRating.include) {
                                            existingStartRating.include = [...new Set([...existingStartRating.include, ...newStartRating.include])];
                                            return [...current];
                                        }

                                        return [...current, newStartRating];
                                    });

                                    if (saveCache) {
                                        addPlayerLocalStorage(playerId, playerName, newStartRating);
                                    }
                                });

                                setPolling(false);
                            }
                        })
                        .catch((error) => {
                            setPolling(false);
                            const label = buildRaterKey(newStartRating);
                            const errorMessage = error instanceof Error ? error.message : "unkown";
                            errors.push(`processing advanced sim job failed for ${label} [${errorMessage}]`);
                        });
                })
                .catch((error) => {
                    const errorMessage = error instanceof Error ? error.message : "unkown";
                    errors.push(`POST ${config.apiStandard}/api/simulation/job failed [${errorMessage}]`);
                });
        }

        handleSimIncludePlayersAsync().then(() => { }).catch(() => { });
    };

    /**
     * Reset Line Chart data when user clicks reset button
     */
    const handleResetButton = () => {
        // Get lines from line chart that were generated by new start rating feature (non default lines)
        const filteredDataSet = dataSet.filter((data) => {
            const prefixMatch = !RATERS_OVERRIDE_PREFIXES.find((prefix) => data.label.startsWith(prefix));
            return prefixMatch;
        });
        // Clear local storage
        removePlayerLocalStorage(playerId);
        // Clear state
        setDataSet(filteredDataSet);
        setRenderedRatings([]);
        setResetSuccess(true);
    };

    // wait for API data to load from Player.tsx
    React.useEffect(() => {
        setLoading(dataSet.length === 0);
    }, [dataSet]);

    React.useEffect(() => {
        if (initialNewStartRatings && initialNewStartRatings.length > 0) {
            initialNewStartRatings.forEach((insr) => handleNewStartRating(insr));
        }
        // handleNewStartRating causes unnecessary rerenders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialNewStartRatings]);

    // Broser refresh logic
    React.useEffect(() => {
        // Players that were generated with rapid off setting
        const players = JSON.parse(window.localStorage.getItem("players") || "[]") as LocalStoragePlayers[];
        const newStartRatingsCache = players.find((player) => player.playerId === playerId)?.newStartRatings || [];
        newStartRatingsCache.forEach((newStartRating) => {
            if (!newStartRating.rapid) {
                handleSimIncludePlayers(newStartRating, false, false);
            }
        });

        if (!defaultOtherPlayers.length) {
            return;
        }

        // Reload players that were added through search component
        const reloadOtherPlayers = defaultOtherPlayers.filter((directoryEntry) => (
            !dataSet.find((ds) => isOtherPlayerLabel(ds.label, directoryEntry.player_id))
        ));

        reloadOtherPlayers.forEach((otherPlayer) => {
            addOtherPlayerToLineChart(otherPlayer);
            setOtherPlayers((current) => [...current, otherPlayer]);
        });

        // remember state on browser refresh (only need to render once)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (errors.length) {
        return (
            <Alert severity="error" data-testid="Player_errorAlert">
                <Stack spacing={2}>
                    <AlertTitle>Error</AlertTitle>
                    <ul>
                        {errors.map((item) => (
                            <li key={item}>{item}</li>
                        ))}
                    </ul>
                    <Typography>Troubleshooting option: click reset button and then refresh page</Typography>
                    <Button
                        variant="contained"
                        onClick={handleResetButton}
                        sx={{ textTransform: "none", minWidth: 75 }}
                        data-testid="Player_resetButton"
                    >
                        Reset
                    </Button>
                </Stack>
            </Alert>
        );
    }

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="50vh"
            >
                <CircularProgress size={50} thickness={4} />
            </Box>
        );
    }

    return (
        <>
            <Snackbar autoHideDuration={3000} open={resetSuccess} onClose={() => setResetSuccess(false)}>
                <Alert
                    severity="success"
                    onClose={() => setResetSuccess(false)}
                    variant="filled"
                    data-testid="Player_resetAlert"
                >
                    graph is reset
                </Alert>
            </Snackbar>
            <Snackbar autoHideDuration={3000} open={duplicate} onClose={() => setDuplicate(false)}>
                <Alert
                    severity="info"
                    onClose={() => { setDuplicate(false); }}
                    variant="filled"
                    data-testid="Player_duplucateAlert"
                >
                    start rating exists
                </Alert>
            </Snackbar>
            <Snackbar open={pollingAlert} onClose={() => setPollingAlert(false)}>
                <Alert
                    severity="warning"
                    onClose={() => { setPollingAlert(false); }}
                    variant="filled"
                    data-testid="Player_duplucateAlert"
                >
                    please wait until current job completes to use rapid off
                </Alert>
            </Snackbar>
            <Grid container spacing={1} sx={{ ml: desktopMatch ? 1 : 0, mt: 1 }} data-testid="LineChartInteractive_grid">
                <Grid md={7} xs={12}>
                    <Stack
                        spacing={1}
                        direction={desktopMatch ? "row" : "column"}
                        alignItems="center"
                        sx={{}}
                    >
                        <OverrideRatingDialog
                            disable={loading}
                            defaultPlayerId={playerId}
                            handleSubmitParent={(newStartRating, rapid) => {
                                const rendered = renderedRatings.find((renderedRating) => {
                                    const curInclude = renderedRating.include ? [...renderedRating.include].sort().join("") : null;
                                    const newInclude = newStartRating.include ? [...newStartRating.include].sort().join("") : null;
                                    const sameInput = sameNewStartRating(renderedRating, newStartRating);

                                    if (newInclude) {
                                        return sameInput && (curInclude === newInclude);
                                    }

                                    return sameInput;
                                });

                                if (rendered) {
                                    setDuplicate(true);
                                    return;
                                }

                                if (newStartRating.include && !rapid) {
                                    if (polling) {
                                        setPollingAlert(true);
                                        return;
                                    }

                                    // For the time being, don't save rapid off sims in localStorage.
                                    // If a user has a few sims generated (with rapid off) and they
                                    // refresh the page, then > 1 will get submitted as a sim job.
                                    // The problem is each one with rapid off takes 30+ seconds
                                    // so this will lead to performance problems and 500 errors in
                                    // backend. Rapid off is a special use case that is intended
                                    // to be used sparingly (also, currently the UI only allows
                                    // 1 ongoing job)
                                    handleSimIncludePlayers(newStartRating, rapid, false);
                                    return;
                                }

                                addPlayerLocalStorage(playerId, playerName, newStartRating);
                                handleNewStartRating(newStartRating);
                            }}
                        />
                        <Button
                            variant="outlined"
                            disabled={loading}
                            onClick={handleResetButton}
                            sx={{ textTransform: "none", minWidth: 75 }}
                            data-testid="Player_resetButton"
                        >
                            Reset
                        </Button>
                    </Stack>
                </Grid>
                <Grid md={5} xs={12}>
                    <Stack direction="row" alignItems="center" spacing={desktopMatch ? 1 : 0}>
                        <PlayersAutoComplete
                            filterPlayers={[playerId]}
                            handleAddPlayers={handleAddOtherPlayers}
                            defaultPlayers={defaultOtherPlayers}
                            multiple
                        />
                        <TuneIcon onClick={() => setOpenTuneDialog(true)} />
                        <RaterCheckboxGroupDialog
                            defaultChecked={checkedRaters}
                            open={openTuneDialog}
                            handleChange={(selectedRaters: Record<string, boolean>) => {
                                setCheckedRaters(selectedRaters);
                                setOpenTuneDialog(false);
                                localStorage.setItem("checkedRaters", JSON.stringify(selectedRaters));
                            }}
                        />
                    </Stack>
                </Grid>
            </Grid>
            {
                polling && (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography sx={{ mr: 1, fontStyle: "italic" }} variant="body2">(loading advanced simulation)</Typography>
                        <LinearProgress variant="query" sx={{ width: "10%" }} />
                    </Box>
                )
            }
            <Grid xs={12}>
                {
                    loading === false && <LineChart dataSet={dataSet} />
                }
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "left", m: 2 }}>
                <InfoIcon color="action" fontSize="large" onClick={() => setChartInfo(true)} />
            </Box>
            <ChartInfoDialog
                open={chartInfo}
                handleClose={() => setChartInfo(false)}
            />
        </>
    );
}
